export default class WindowServerData {
	constructor() {
		this.loadEnvs()
	}

	loadEnvs() {
		let envData = {}

		if (window?.serverdata) {
			try {
				const decoded = atob(window.serverdata)
				envData = JSON.parse(decoded)
			} catch (e) {
				console.error('Erro ao decodificar ou parsear serverData', e)
				envData = process.env
			}
		} else {
			envData = process.env
		}

		for (const key in envData) {
			this[key] = envData[key]
		}
	}
}

export const envs = new WindowServerData()
